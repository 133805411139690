import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"

const getTreatments = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(getTreatments)

  return (
    <Layout>
      <Banner
        title="Privacy Policy"
        ishome={false}
        image={data.fileName.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-content">
            <div className="container">
              <div className="row">
                <div className="c-content__text">
                  <p>Here at Step2Wellbeing we take your privacy very seriously and will only use your personal information to administer your account and to provide you with the services you have requested from us.</p>
                  <p>We will securely store your data and whilst we may share the details amongst the team at Step2Wellbeing we will never pass your details onto third parties.</p>
                  <p>The information we collect from you is only used to provide tailored treatment to you in a safe manner in line with our legal, and medical obligations. This is our duty of care to you. We will safeguard your records for up to 7 years (this is our contractual obligation) and will erase any data that is not required or out of date.</p>
                  <p>We may at times provide you with information on other treatments which we think you may be interested in and notify you of any important changes to our services and our business news. This meets our contractual commitments to you.</p>
                  <p>You may instruct us to provide you with any personal information we hold about you by emailing: <a href="mailto:step2wellbeing@gmail.com">step2wellbeing@gmail.com</a></p>
                  <p>Please let us know if the personal information which we hold about you needs to be corrected or updated.</p>
                  <p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
                  <p>We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Testimonials />
    </Layout>
  )
}
